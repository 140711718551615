<template>
  <default-layout :searchFunction="loadData" searchParamString="q">
    <v-container fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <list :items="items" :meta="meta" :permissions="permissions" @changePage="changePage"></list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const List = () => import('@/components/referral-management/referral/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    List,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.referral.permissions,
      items: (state) => state.referral.items,
      meta: (state) => state.referral.meta,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('referral/RESET_DEFAULT');
      const response = await this.$store.dispatch('referral/getInitData', query);
      console.log('CHECK REFERRAL INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('referral/getData', query);
        console.log('GET REFERRAL DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      await this.loadData(query);
    },
  },
};
</script>
